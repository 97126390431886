import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Form, Container, Row, Col, Button, Spinner, Card, CardColumns } from 'react-bootstrap';
import axios from 'axios'
import './recipefinder.css'

class RecipeFinder extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			results: [],
			showFilters: false
		};

		this.fetchRecipes = this.fetchRecipes.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.fetchRecipes();
	}

	handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(event.target);
		let url = '/.netlify/functions/spoonacular?';
		for (var [key, value] of formData.entries()) { 
			url += key + '=' + value + '&';
			this.setState({
				key: value
			})
		}
		this.setState({
			showFilters: false
		})
		this.fetchRecipes(url);	
	}

	toggleFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		})
	}

	fetchRecipes = (url) => {
		var self = this;
		
		self.setState({loading: true})
		let fetchUrl = (url) ? url : '/.netlify/functions/spoonacular?minProtein=0&maxProtein=50&minCarbs=0&maxCarbs=50&minFat=0&maxFat=50&maxSugar=25&diet=';
		
		axios
			.get(fetchUrl)
			.then(function(response){
				//handle success
				console.log(response.data.results);
				self.setState({results:response.data.results})
			})
			.catch(function(error){
				//handle error
				console.log(error)
			})
			.finally(function(){
				//always executed
				self.setState({loading: false})
			})
	}

	render() {
		return (
			<Container>
			<Button className="toggle-button" onClick={this.toggleFilters} size="sm" variant="primary" >Toggle Filters</Button>
			<Row>
			<br/>

				<Col lg={3} className={this.state.showFilters ? 'filter-list show' : 'filter-list'}>
				<Form onSubmit={this.handleSubmit}>

				  	<Form.Group controlId="formBasicInputs">
						<Form.Label>Minimum Protein:</Form.Label>
					    <Form.Control type="number" name="minProtein" defaultValue="0" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Min Protein (g)" />
					  
						<Form.Label>Maximum Protein:</Form.Label>
					    <Form.Control type="number" name="maxProtein" defaultValue="50" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Max Protein (g)" />
					 
						<Form.Label>Minimum Carb:</Form.Label>
					    <Form.Control type="number" name="minCarbs" defaultValue="0" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Min Carb (g)" />
					 
						<Form.Label>Maximum Carb:</Form.Label>
					    <Form.Control type="number" name="maxCarbs" defaultValue="50" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Max Carb (g)" />
					  
						<Form.Label>Minimum Fat:</Form.Label>
					    <Form.Control type="number" name="minFat" defaultValue="0" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Min Fat (g)" />
					  
						<Form.Label>Maximum Fat:</Form.Label>
					    <Form.Control type="number" name="maxFat" defaultValue="50" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Max Fats (g)" />
					  
						<Form.Label>Maximum Sugar:</Form.Label>
					    <Form.Control type="number" name="maxSugar" defaultValue="25" min="0" required="required" pattern="[A-Za-z0-9]{1,20}" placeholder="Enter Max Sugar (g)" />
					  
	    				<Form.Label>Select Diet Type:</Form.Label>
	    				<Form.Control as="select" name="diet">
	    					<option value="">Select Diet</option>
			            	<option value="whole30">Whole30</option>
			            	<option value="ketogenic">Ketogenic</option>
			            	<option value="vegetarian">Vegetarian</option>
			            	<option value="lacto vegetarian">Lacto-Vegetarian</option>
			            	<option value="ovo vegetarian">Ovo-Vegetarian</option>
			            	<option value="vegan">Vegan</option>
			            	<option value="pescetarian">Pescetarian</option>
			            	<option value="paleo">Paleo</option>
			            	<option value="primal">Primal</option>
			            	<option value="dairy free">Dairy Free</option>
	    				</Form.Control>

  					</Form.Group>

				  	<Button variant="primary" type="submit">
				  		Submit
				  	</Button>
				</Form>
				</Col>
			
				<Col>


				<CardColumns>
					{this.state.loading ? (
						<div><Spinner animation="border" /></div>
					) : ( <>{this.state.results.map((item, key)=>
							<Card key={key}>
								<Card.Img variant="top" src={item.image} />
								<Card.Body>
									<Card.Title>{item.title}</Card.Title>
									<Card.Text>
									    Protein: {item.nutrition[0].amount}{item.nutrition[0].unit}
									    <br></br>Carbs: {item.nutrition[2].amount}{item.nutrition[2].unit}
									    <br></br>Fats: {item.nutrition[1].amount}{item.nutrition[1].unit}
									    <br></br>Sugar: {item.nutrition[3].amount}{item.nutrition[3].unit}
									</Card.Text>
									<Button variant="primary" href={item.sourceUrl} target="_blank">Go to Recipe</Button>
								</Card.Body>
							</Card>
						)}</>
					)}
				</CardColumns>
				</Col>
				</Row>
			</Container>
		)
	}

}

export default RecipeFinder
