import React from "react";
import Layout from "../components/layout";
import RecipeFinder from "../components/recipefinder.js";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

export default () => (
  <div>
    <Layout>
    	<h4>Enter your macros for some delicious recipes.</h4>
    	<br/>
    	<RecipeFinder/>
    </Layout>

  </div>
);